import { yupResolver } from '@hookform/resolvers/yup';
import { meKey, useLogin } from 'api-hooks/auth';
import { queryClient } from 'common/api/query-client';
import { clearSessionData } from 'common/auth';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { KurosimTimezone } from 'common/utils/date';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import useTurnstile from 'hooks/use-turnstile';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useKurosimCheckEmail from './use-kurosim-check-email';
import { LoginFormSchema, LoginFormType } from '../email/form-type';
import { popOutOfLoginFlow } from '../register/profile/utils';

export default function useKurosimLogin() {
  const { mutateAsync: loginAsync } = useLogin();
  const { refresh } = useKurosimNavigation();
  const onCheckEmail = useKurosimCheckEmail();
  const { t } = useTranslation();
  const { turnstileComponent, captcha, onResetCaptcha } = useTurnstile();

  const defaultValues = React.useMemo<LoginFormType>(() => {
    return {
      email: '',
      password: '',
    };
  }, []);

  const methods = useForm({
    resolver: yupResolver(LoginFormSchema()),
    defaultValues,
    mode: 'onChange',
  });

  const { setError } = methods;

  const onSubmit = React.useCallback(
    async (values: LoginFormType) => {
      try {
        const { inUse, provider } = await onCheckEmail(values.email, captcha);

        // check email existance
        if (!inUse) {
          setError('email', {
            type: 'manual',
            message: t('profile:email_not_registered_yet'),
          });
          return;
        }

        if (provider === 'apple' || provider === 'google') {
          notification.info({ message: t(`profile:login_with_${provider}`) });
          return;
        }

        await clearSessionData();

        const timezone = KurosimTimezone.get();

        const loginResult = await loginAsync({
          ...values,
          cf_turnstile_response: captcha,
          timezone,
        });

        notification.success({
          message: loginResult.message,
        });

        //replacement
        popOutOfLoginFlow();
        refresh();
      } catch (err) {
        console.error(err);
        if (err.errors) {
          formSetErrors(err.errors, setError);
        }
        notification.error({ message: err.message });
      } finally {
        onResetCaptcha();
        queryClient.invalidateQueries(meKey.getMeKey);
      }
    },
    [captcha, loginAsync, onCheckEmail, onResetCaptcha, refresh, setError, t],
  );

  return {
    methods,
    onSubmit,
    turnstileComponent,
    captcha,
  };
}
