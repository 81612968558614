import { Button } from '@mantine/core';
import { AssetPaths } from 'common/constants/assets';
import { NavigationRoutes } from 'common/routes';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/fields';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import Image from 'next/image';
import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import AgreementComponent from '../components/agreement-component';
import RedirectionAuthComponent from '../components/redirection-auth-component';
import SSOComponent from '../components/sso-component';
import useKurosimLogin from '../hooks/use-kurosim-login';

interface Props {}

export default function EmailComponent(props: Props) {
  const { t } = useTranslation();

  const { methods, onSubmit, turnstileComponent, captcha } = useKurosimLogin();

  const { push } = useKurosimNavigation();

  const forgotPasswordComponent = (
    <>
      <Separator gap={4} />
      <Button
        style={{
          alignSelf: 'end',
        }}
        size="compact-xs"
        ta="right"
        variant="transparent"
        onClick={() => {
          push(NavigationRoutes.ForgotPassword, {
            locker: {
              email: methods.getValues('email'),
            },
          });
        }}
      >
        <Text textVariant="body2Regular">{t('common:forgot_password')}?</Text>
      </Button>
      <Separator gap={16} />
    </>
  );
  return (
    <div style={styles.container}>
      <div
        style={styles.iconContainer}
        onClick={() => {
          push(NavigationRoutes.MySim);
        }}
      >
        <Image width={80} height={80} alt="kurosim" src={AssetPaths.AppIcon} />
      </div>

      <Separator gap={24} />
      <Trans
        i18nKey="common:kurosim_title"
        components={[
          <Text span textVariant="display" fw={400} key="0" />,
          <Text span textVariant="display" key="1" />,
          <Text span textVariant="display" fw={400} key="2" />,
        ]}
      />
      <Separator gap={16} />
      <Text textVariant="body1Regular">{t('common:kurosim_subtitle')}</Text>
      <Separator gap={24} />
      <SSOComponent />
      <Separator gap={16} />
      <div>
        <Form methods={methods} onSubmit={onSubmit}>
          <Input
            type="email"
            name="email"
            label={t('common:email')}
            placeholder={t('common:email_placeholder')}
            noMargin
          />
          <Separator gap={16} />
          <>
            <Input
              type="password"
              name="password"
              label={t('common:password')}
              placeholder={t('common:enter_extra', {
                extra: t('common:password'),
              })}
              noMargin
              maxLength={50}
            />
            {forgotPasswordComponent}
          </>

          {turnstileComponent}

          <Input type="submit" disabled={!captcha} text={t('common:login')} />
        </Form>
      </div>
      <Separator gap={16} />
      <RedirectionAuthComponent type="login" />
      <Separator gap={16} />
      <AgreementComponent />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  } as React.CSSProperties,
  iconContainer: {
    width: 'fit-content',
    cursor: 'pointer',
    margin: '0px auto',
  } as React.CSSProperties,
};
